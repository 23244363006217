import createFetchDuck from '../../util/genericEntityDuck';
import { Selector } from 'extensible-duck';

export const storeKey = 'actions';
const duck = createFetchDuck('ASSETS', storeKey, 'id', 'assets').extend({
  types: ['SET_ACTIONS_META', 'SET_ACTIONS_VIEW_COLUMNS'],
  initialState: {
    data: [],
    meta: {
      limit: undefined,
      offset: undefined,
      total: undefined,
    },
    viewColumns: {},
  },
  reducer: (state, action, { types }) => {
    switch (action.type) {
      case types.SET_ACTIONS_META:
        return { ...state, meta: action.data };
      case types.SET_ACTIONS_VIEW_COLUMNS:
        return { ...state, viewColumns: action.data };
      default:
        return state;
    }
  },
  selectors: {
    meta: new Selector(selectors => state => selectors.entities(state).meta),
    viewColumns: new Selector(selectors => state => selectors.entities(state).viewColumns),
  },
  creators: ({ types }) => ({
    setMeta: data => ({ type: types.SET_ACTIONS_META, data }),
    setViewColumns: data => ({ type: types.SET_ACTIONS_VIEW_COLUMNS, data }),
  }),
});

export default duck;
export const { creators, reducer, types, selectors } = duck;
