import React, { useEffect, useMemo } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { useFeatureToggle } from 'sharedComponents/FeatureToggle';
import { getItem, setItem } from 'util/localStorage';
import { FEATURE_TOGGLES } from '../ducks/featureDuck';
import { selectors as configurationSelectors, THEME_KEY } from '../ducks/configurationDuck';
import darkTheme from './darkTheme';
import lightTheme from './lightTheme';
import geTheme from './geTheme';

const useDarkMode = () => {
  const configuration = useSelector(state => configurationSelectors.configuration(state), shallowEqual);
  useEffect(() => {
    const theme = getItem(THEME_KEY);
    if (theme !== configuration.theme) {
      setItem(THEME_KEY, configuration.theme);
    }
  }, [configuration.theme]);
  return configuration.theme !== 'light';
};

const ThemeProvider = ({ children }) => {
  const darkMode = useDarkMode();
  const geThemeFeature = useFeatureToggle(FEATURE_TOGGLES.GE_THEME);
  const theme = useMemo(() => {
    if (geThemeFeature) {
      return geTheme;
    }
    return darkMode ? darkTheme : lightTheme;
  }, [darkMode, geThemeFeature]);
  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
};

export default ThemeProvider;
