import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { defineMessage } from 'react-intl';
import qs from 'qs';
import SMOCIcon from 'utilihive-icons/MeteringPoint';
import MeteringPointIcon from 'utilihive-icons/MeteringPoint';
import DashboardIcon from 'utilihive-icons/Dashboard';
import ValidationIcon from 'utilihive-icons/Validation';
import MODULE_NAMES from 'App/config/moduleNames';

export const MODULE_PATH = 'market';

export const ACTIONS = 'actions';
export const ASSETS = 'assets';
export const APPROVALS = 'approvals';
export const DASHBOARD = '*';
export const AUDIT_LOG = 'auditlog';
export const ASSET = 'assets/:assetId';

const paramFields = ['fromDate', 'toDate', 'dateOptionIndex'];

const getParams = search => {
  const cleaned = search.replace('?', '');
  const params = qs.parse(cleaned);
  const newParams = {};
  paramFields.forEach(fieldKey => {
    if (params[fieldKey]) {
      newParams[fieldKey] = params[fieldKey];
    }
  });
  const stringified = qs.stringify(newParams);
  return stringified ? `?${stringified}` : '';
};

const children = [
  {
    path: DASHBOARD,
    index: true,
    lazy: () => import(/* webpackChunkName: "Market:Dashboard" */ '../scenes/Dashboard'),
    intlMessage: defineMessage({ id: 'route.market.dashboard', defaultMessage: 'Dashboard' }),
    Icon: DashboardIcon,
  },
  {
    path: APPROVALS,
    lazy: () => import(/* webpackChunkName: "Market:Assets" */ '../scenes/Approvals'),
    intlMessage: defineMessage({ id: 'route.market.approvals', defaultMessage: 'Appointments' }),
    Icon: ValidationIcon,
  },
  {
    path: ASSETS,
    lazy: () => import(/* webpackChunkName: "Market:Assets" */ '../scenes/Assets'),
    intlMessage: defineMessage({ id: 'route.market.assets', defaultMessage: 'MPAN' }),
    Icon: MeteringPointIcon,
  },
  {
    path: ASSET,
    lazy: () => import(/* webpackChunkName: "Market:Asset" */ '../scenes/Asset'),
  },
];

const market = {
  path: MODULE_PATH,
  lazy: () => import(/* webpackChunkName: "Market" */ 'Market'),
  intlMessage: defineMessage({ id: 'route.market', defaultMessage: 'Market' }),
  Icon: <SMOCIcon />,
  moduleNameForOrganisationAccess: MODULE_NAMES.MARKET,
  children,
};

export const useRoutes = () => {
  const location = useLocation();
  const routes = useMemo(
    () => ({
      intlMessage: market.intlMessage,
      Icon: market.Icon,
      root: market.path,
      moduleNameForOrganisationAccess: market.moduleNameForOrganisationAccess,
      routes: market.children
        .filter(child => !!child.intlMessage)
        .map(({ path, Icon, intlMessage, index }) => ({
          path,
          intlMessage,
          Icon,
          index,
          params: getParams(location.search),
        })),
    }),
    [location]
  );
  return routes;
};

export default market;
