import { login } from 'utilihive-authentication/client';
import errorCodes from '../../util/errorCodes';
import { creators as authCreators } from '../ducks/authDuck';
import { loginUrl } from '../api/appUrls';
import { loginWithActiveDirectory } from '../api/appApi';
import { tokenDuck } from '../../store/jwtMiddleware';
import { initialize } from './init';

export const loginAction = (username, password) => async dispatch => {
  dispatch(authCreators.loginStart());
  try {
    const { token, refreshToken, expires } = await login({ username, password, loginUrl });
    if (!(token && refreshToken && expires)) {
      throw new Error(errorCodes.HTTP_RESPONSE_STATUS_UNAUTHORIZED);
    }
    dispatch(tokenDuck.creators.setToken(token, refreshToken, expires));
    dispatch(initialize());
    dispatch(authCreators.loginSuccess());
  } catch (error) {
    dispatch(authCreators.loginError(error));
  }
};

const expiresToDateString = expires => new Date(new Date().getTime() + expires * 1000).toString();

export const activeDirectoryAction = adToken => async dispatch => {
  dispatch(authCreators.loginStart());
  try {
    const { access_token: token, refresh_token: refreshToken, expires_in: expires } = await loginWithActiveDirectory(
      adToken
    );
    if (!(token && refreshToken && expires)) {
      throw new Error(errorCodes.HTTP_RESPONSE_STATUS_UNAUTHORIZED);
    }
    dispatch(tokenDuck.creators.setToken(token, refreshToken, expiresToDateString(expires)));
    dispatch(initialize());
    dispatch(authCreators.loginSuccess());
  } catch (error) {
    dispatch(authCreators.loginError(error));
  }
};

export const logoutAction = () => dispatch => {
  dispatch(authCreators.logout());
  dispatch(tokenDuck.creators.clearToken());
};
