import { useEffect } from 'react';

const GoogleAnalyticsLoader = () => {
  useEffect(() => {
    const googleAnalyticsUrl = window && window.app && window.app.GA_CONFIG;
    if (!googleAnalyticsUrl) {
      return;
    }
    const script = document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsUrl}`;
    script.onload = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag('js', new Date());
      gtag('config', googleAnalyticsUrl);
    };
    document.head.appendChild(script);
  }, []);

  return null;
};

export default GoogleAnalyticsLoader;
