import createFetchDuck from '../../util/genericEntityDuck';
import { Selector } from 'extensible-duck';

export const storeKey = 'events';
const duck = createFetchDuck('datalake', storeKey, 'mrId', 'datalake').extend({
  types: ['SET_COUNT'],
  initialState: {
    data: [],
    count: 0,
  },
  reducer: (state, action, { types }) => {
    switch (action.type) {
      case types.SET_COUNT:
        return { ...state, count: action.count };
      default:
        return state;
    }
  },
  selectors: {
    count: new Selector(selectors => state => selectors.entities(state).count),
  },
  creators: ({ types }) => ({
    setCount: count => ({ type: types.SET_COUNT, count }),
  }),
});

export default duck;
export const { creators, reducer, types, selectors } = duck;
