import { createTheme } from '@mui/material';
import palette from './geDark';
import '@fontsource/roboto/latin.css';

const theme = createTheme({
  components: {
    MuiPaper: {
      defaultProps: {
        square: true,
      },
    },
    MuiPopover: {
      styleOverrides: {
        colorDefault: {
          backgroundColor: palette.surface['4'],
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          top: 0,
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        colorDefault: {
          backgroundColor: palette.surface['7'],
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        textPrimary: {
          color: palette.borders.focus,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          padding: '8px 34px 8px 10px !important',
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        '&::-webkit-scrollbar': {
          width: '5px',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: `rgba(255, 255, 255, 0.1)`,
          borderRadius: '10px',
        },
      },
    },
    MUIDataTableBodyCell: {
      styleOverrides: {
        root: {
          padding: 5,
        },
      },
    },
    MUIDataTableHeadCell: {
      styleOverrides: {
        root: {
          padding: 5,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&.Mui-disabled .Mui-disabled': {
            WebkitTextFillColor: 'rgba(255, 255, 255, 0.8)',
          },
        },
      },
    },
    MUIDataTable: {
      styleOverrides: {
        liveAnnounce: {
          position: 'relative',
        },
      },
    },
  },
  palette,
});

export default theme;
