import { combineReducers } from 'redux';

import { reducer as meteringPointDucks, storeKey as meteringPointDuckKey } from './meteringPointDuck';
import { reducer as searchDucks, storeKey as searchKey } from './searchDuck';
import { reducer as meterReadingsRawDucks, storeKey as meterReadingsRawKey } from './meterReadingsRawDuck';
import { reducer as assetsReducer, storeKey as assetsKey } from './assets';
import { reducer as dashboardReducer, storeKey as dashboardKey } from './dashboard';
import { reducer as dashboardDayReducer, storeKey as dashboardDayKey } from './dashboardDay';
import { reducer as performanceStatsReducer, storeKey as performanceStatsKey } from './performanceStats';

const vee = combineReducers({
  [meteringPointDuckKey]: meteringPointDucks,
  [searchKey]: searchDucks,
  [meterReadingsRawKey]: meterReadingsRawDucks,
  [assetsKey]: assetsReducer,
  [assetsKey]: assetsReducer,
  [dashboardKey]: dashboardReducer,
  [performanceStatsKey]: performanceStatsReducer,
  [dashboardDayKey]: dashboardDayReducer,
});

export default vee;
