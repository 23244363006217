import { connect } from 'react-redux';
import { selectors as configurationSelectors } from '../../../ducks/configurationDuck';
import { selectors as userSelectors } from '../../../ducks/userDuck';
import { selectors as featureSelectors } from '../../../ducks/featureDuck';
import { selectors as authSelectors } from '../../../ducks/authDuck';
import { featuresAction as fetchFeatures } from '../../../actions/features';

import Initialize from './Initialize';

const mapStateToProps = state => ({
  modules: configurationSelectors.modules(state),
  configurationInitialized: configurationSelectors.initialized(state) || !!configurationSelectors.fetchError(state),
  user: userSelectors.user(state),
  organizationShortName: userSelectors.organizationShortName(state),
  featuresInitialized: featureSelectors.initialized(state) || !!featureSelectors.fetchError(state),
  token: authSelectors.token(state),
});

const mapDispatchToProps = {
  fetchFeatures,
};

export default connect(mapStateToProps, mapDispatchToProps)(Initialize);
