import React from 'react';
import { Outlet } from 'react-router-dom';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import Box from '@mui/material/Box';

import Nav from '../../components/Navigation';
import Container from './Container';
import Toasts from './Toasts';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import Initialize from './Initialize';

const AppWrapper = () => {
  return (
    <QueryParamProvider adapter={ReactRouter6Adapter}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Initialize>
          <Container>
            <Nav />
            <Toasts />
            <Box component="main" sx={{ zIndex: 0, display: 'grid', gridTemplateColumns: 'auto 1fr', width: '100vw' }}>
              <Outlet />
            </Box>
          </Container>
        </Initialize>
      </LocalizationProvider>
    </QueryParamProvider>
  );
};

export default AppWrapper;
