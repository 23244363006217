import React from 'react';
import { FormattedMessage } from 'react-intl';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';

const LOCALE_BASE = 'Datalake-assetModal-dataLog-interval-';

export const INTERVALS = ['FixedBlock60Min', 'FixedBlock15Min'];
export const DEFAULT_INTERVAL = INTERVALS[0];

const IntervalSelect = ({ interval, onChange, className, sx }) => (
  <FormControl className={className} sx={sx}>
    <InputLabel htmlFor="interval-select">
      <FormattedMessage id="Datalake-assetModal-dataLog-interval-label" defaultMessage="Interval" />
    </InputLabel>
    <Select
      value={interval}
      onChange={e => onChange(e.target.value)}
      inputProps={{
        name: 'Interval',
        id: 'interval-select',
      }}
    >
      {INTERVALS.map(interval => (
        <MenuItem key={interval} value={interval}>
          <FormattedMessage id={`${LOCALE_BASE}${interval}`} defaultMessage={interval} />
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

export default IntervalSelect;
