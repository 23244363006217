import { get, post } from 'utilihive-http';
import {
  resetPasswordEndpoint,
  sendResetPasswordMailEndpoint,
  isResetPasswordTokenValidEndpoint,
  activeDirectoryEndpoint,
} from './appUrls';
import { configuration as configurationEndpoint } from '../../config/backendRoutes';

export const resetPassword = (setPasswordToken, requestedPassword, repeatedPassword) =>
  post(resetPasswordEndpoint, { body: { setPasswordToken, newPassword: { requestedPassword, repeatedPassword } } });

export const loginWithActiveDirectory = token =>
  get(activeDirectoryEndpoint, { token, contentType: 'application/x-www-form-urlencoded' });

export const sendResetPasswordMail = email => post(sendResetPasswordMailEndpoint, { body: { email } });

export const isPasswordResetTokenValid = token => get(isResetPasswordTokenValidEndpoint(token), { parseResult: false });

export const features = (token, organizationShortName) =>
  get(`${configurationEndpoint}/v1/${organizationShortName}/features`, { token });

export const featuresV2 = (token, organizationShortName) =>
  get(`${configurationEndpoint}/v2/${organizationShortName}/features`, { token });
