export const defaultLocale = 'en';

export const SUPPORTED_LOCALES = ['en', 'no', 'fr', 'zh'];

const localeToMomentLocalesMap = {
  en: 'en',
  no: 'nb',
  fr: 'fr',
  zh: 'zh',
};

export const getMomentLocaleFromLocale = locale => localeToMomentLocalesMap[locale];
