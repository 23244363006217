import React from 'react';
import { FormattedMessage } from 'react-intl';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';

const LOCALE_BASE = 'Datalake-assetModal-dataLog-aggregation-';

export const AGGREGATIONS = ['None', 'Average', 'Sum', 'Maximum', 'Minimum'];
export const DEFAULT_AGGREGATION = AGGREGATIONS[0];
export const DEFAULT_AGGREGATION_GROUP = AGGREGATIONS[2];

const AggregationSelect = ({ aggregation, onChange, className, disableNone, sx }) => (
  <FormControl className={className} sx={sx}>
    <InputLabel htmlFor="interval-select">
      <FormattedMessage id="Datalake-assetModal-dataLog-aggregation-label" defaultMessage="Aggregation" />
    </InputLabel>
    <Select
      value={aggregation}
      onChange={e => onChange(e.target.value)}
      inputProps={{
        name: 'Aggregation',
        id: 'aggregation-select',
      }}
    >
      {AGGREGATIONS.filter(aggregation => (disableNone ? aggregation !== AGGREGATIONS[0] : true)).map(aggregation => (
        <MenuItem key={aggregation} value={aggregation}>
          <FormattedMessage id={`${LOCALE_BASE}${aggregation}`} defaultMessage={aggregation} />
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

export default AggregationSelect;
