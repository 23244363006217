import { Selector } from 'extensible-duck';
import { baseDuck } from '../../util/genericEntityDuck';
import moment from 'moment';
import { dateRangeOptions } from '../domain/dateRange';
import { DEFAULT_INTERVAL } from '../components/Form/IntervalSelect';
import { DEFAULT_MACRO_PERIOD } from '../components/Form/MacroPeriodSelect';
import { DEFAULT_STRIDE_PERIOD } from '../components/Form/StridePeriodSelect';
import { DEFAULT_ACCUMULATION } from '../components/Form/AccumulationSelect';
import { DEFAULT_AGGREGATION } from '../components/Form/AggregationSelect';
import { DEFAULT_MACRO_AGGREGATE } from '../components/Form/MacroAggregateSelect';

export const storeKey = 'assetDataLog2';

const DEFAULT_DATE_OPTION_INDEX = 1;

const isAfterNow = date => {
  const nowMoment = moment();
  return date.isSameOrAfter(nowMoment) ? nowMoment : date;
};

const duck = baseDuck().extend({
  namespace: 'datalake',
  store: storeKey,
  types: [
    'SET_INTERVAL',
    'SET_CHANNELS',
    'SET_COMPARE',
    'SET_COMPARE_ID',
    'SET_ADVANCED',
    'SET_TABLE',
    'SET_DATE_OPTION_INDEX',
    'SET_START_DATE',
    'SET_END_DATE',
    'SET_COMPARISONS',
    'SET_SOURCE',
    'SET_PARENT_SOURCE',
    'SET_AVAILABLE_SOURCE',
  ],
  initialState: {
    interval: DEFAULT_INTERVAL,
    channels: [],
    dateOptionIndex: DEFAULT_DATE_OPTION_INDEX,
    startDate: dateRangeOptions()[DEFAULT_DATE_OPTION_INDEX].fromMoment,
    endDate: isAfterNow(dateRangeOptions()[DEFAULT_DATE_OPTION_INDEX].toMoment),
    compare: false,
    compareId: '',
    advanced: false,
    table: false,
    comparisons: [
      {
        macroPeriod: DEFAULT_MACRO_PERIOD,
        stridePeriod: DEFAULT_STRIDE_PERIOD,
        aggregate: DEFAULT_AGGREGATION,
        macroAggregate: DEFAULT_MACRO_AGGREGATE,
        accumulation: DEFAULT_ACCUMULATION,
      },
    ],
    source: null,
    parentSource: null,
    availableSources: [],
  },
  reducer: (state, action, { types }) => {
    switch (action.type) {
      case types.SET_INTERVAL:
        return { ...state, interval: action.interval };
      case types.SET_CHANNELS:
        return { ...state, channels: action.channels };
      case types.SET_COMPARE:
        return { ...state, compare: action.compare };
      case types.SET_COMPARE_ID:
        return { ...state, compareId: action.compareId };
      case types.SET_ADVANCED:
        return { ...state, advanced: action.advanced };
      case types.SET_TABLE:
        return { ...state, table: action.table };
      case types.SET_DATE_OPTION_INDEX:
        return { ...state, dateOptionIndex: action.dateOptionIndex };
      case types.SET_START_DATE:
        return { ...state, startDate: action.startDate };
      case types.SET_END_DATE:
        return { ...state, endDate: action.endDate };
      case types.SET_COMPARISONS:
        return { ...state, comparisons: action.comparisons };
      case types.SET_SOURCE:
        return { ...state, source: action.source };
      case types.SET_PARENT_SOURCE:
        return { ...state, parentSource: action.parentSource };
      case types.SET_AVAILABLE_SOURCE:
        return { ...state, availableSources: action.availableSources };
      default:
        return state;
    }
  },
  creators: ({ types }) => ({
    setInterval: interval => ({ type: types.SET_INTERVAL, interval }),
    setSource: source => ({ type: types.SET_SOURCE, source }),
    setParentSource: parentSource => ({ type: types.SET_PARENT_SOURCE, parentSource }),
    setAvailableSources: availableSources => ({ type: types.SET_AVAILABLE_SOURCE, availableSources }),
    setChannels: channels => ({ type: types.SET_CHANNELS, channels }),
    setCompare: compare => ({ type: types.SET_COMPARE, compare }),
    setCompareId: compareId => ({ type: types.SET_COMPARE_ID, compareId }),
    setAdvanced: advanced => ({ type: types.SET_ADVANCED, advanced }),
    setTable: table => ({ type: types.SET_TABLE, table }),
    setDateOptionIndex: dateOptionIndex => ({ type: types.SET_DATE_OPTION_INDEX, dateOptionIndex }),
    setStartDate: startDate => ({ type: types.SET_START_DATE, startDate }),
    setEndDate: endDate => ({ type: types.SET_END_DATE, endDate }),
    setComparisons: comparisons => ({ type: types.SET_COMPARISONS, comparisons }),
  }),
  selectors: {
    root: state => state.datalake[storeKey],
    interval: new Selector(selectors => state => selectors.root(state).interval),
    source: new Selector(selectors => state => selectors.root(state).source),
    parentSource: new Selector(selectors => state => selectors.root(state).parentSource),
    availableSources: new Selector(selectors => state => selectors.root(state).availableSources),
    channels: new Selector(selectors => state => selectors.root(state).channels),
    compare: new Selector(selectors => state => selectors.root(state).compare),
    compareId: new Selector(selectors => state => selectors.root(state).compareId),
    advanced: new Selector(selectors => state => selectors.root(state).advanced),
    table: new Selector(selectors => state => selectors.root(state).table),
    dateOptionIndex: new Selector(selectors => state => selectors.root(state).dateOptionIndex),
    startDate: new Selector(selectors => state => selectors.root(state).startDate),
    endDate: new Selector(selectors => state => selectors.root(state).endDate),
    comparisons: new Selector(selectors => state => selectors.root(state).comparisons),
  },
});
export default duck;
export const { creators, reducer, types, selectors } = duck;
