import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { TextFieldInput } from 'sharedComponents/Form';

import Button from '@mui/material/Button';
import MessageBox from 'sharedComponents/MessageBox';
import { PublicPageFormPaper } from '../../presentation/components/PublicPageWrapper';
import { sendResetPasswordMail } from '../../api/appApi';

const ForgotPassword = () => {
  const [, setLoading] = useState(false);
  const [sent, setSent] = useState(false);
  const [error, setError] = useState(undefined);
  const intl = useIntl();

  const { register, handleSubmit } = useForm({
    defaultValues: {
      email: '',
    },
  });

  const sendResetMail = async ({ email }) => {
    try {
      setLoading(true);
      await sendResetPasswordMail(email.toLowerCase());
      setLoading(false);
      setSent(true);
    } catch (e) {
      setLoading(false);
      setError(e.message);
    }
  };
  const emailLabel = intl.formatMessage({ id: 'Login-input-email', defaultMessage: 'Email' });
  const sendResetMailLabel = intl.formatMessage({
    id: 'Login-button-sendResetMail',
    defaultMessage: 'Send reset mail',
  });
  const errorLabel =
    error && intl.formatMessage({ id: 'Login-error-message', defaultMessage: 'An unknown error occurred' });
  const sentMessage = intl.formatMessage({
    id: 'Login-label-messageSent',
    defaultMessage: 'Mail sent, check your inbox.',
  });
  return (
    <PublicPageFormPaper>
      <form onSubmit={handleSubmit(sendResetMail)}>
        <MessageBox message={errorLabel} />
        <TextFieldInput
          registerProps={register('email', { required: true })}
          type="email"
          required
          label={emailLabel}
          autoComplete="username"
          variant="outlined"
          fullWidth
          margin="normal"
          InputLabelProps={{}}
        />
        {sent ? (
          <MessageBox variant="success" message={sentMessage} />
        ) : (
          <Button type="submit" fullWidth variant="contained" color="primary" sx={{ mt: 2, mb: 2 }}>
            {sendResetMailLabel}
          </Button>
        )}
        <Button component={Link} to="/" fullWidth>
          <FormattedMessage id="Login-anchor-back" defaultMessage="Return to login" />
        </Button>
      </form>
    </PublicPageFormPaper>
  );
};

export default ForgotPassword;
