import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { defineMessage } from 'react-intl';
import qs from 'qs';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import MeteringPointIcon from 'utilihive-icons/MeteringPoint';
import AuditIcon from '@mui/icons-material/Policy';
import { USER_ROLES } from 'App/domain/user';
import MODULE_NAMES from 'App/config/moduleNames';

export const MODULE_PATH = 'assets';

export const ACTIONS = '';
export const ASSETS = 'assets';
export const AUDIT_LOG = 'auditlog';
export const ASSET = 'assets/:assetId';

const paramFields = ['fromDate', 'toDate', 'dateOptionIndex'];

const getParams = search => {
  const cleaned = search.replace('?', '');
  const params = qs.parse(cleaned);
  const newParams = {};
  paramFields.forEach(fieldKey => {
    if (params[fieldKey]) {
      newParams[fieldKey] = params[fieldKey];
    }
  });
  const stringified = qs.stringify(newParams);
  return stringified ? `?${stringified}` : '';
};

const children = [
  {
    path: ACTIONS,
    index: true,
    lazy: () => import(/* webpackChunkName: "Assets:Actions" */ '../scenes/Actions'),
    intlMessage: defineMessage({ id: 'route.assets.actions', defaultMessage: 'Actions' }),
    Icon: PendingActionsIcon,
  },
  {
    path: ASSETS,
    lazy: () => import(/* webpackChunkName: "Assets:Assets" */ '../scenes/Assets'),
    intlMessage: defineMessage({ id: 'route.assets.assets', defaultMessage: 'Assets' }),
    Icon: MeteringPointIcon,
  },
  {
    path: ASSET,
    lazy: () => import(/* webpackChunkName: "Assets:Asset" */ '../scenes/Asset'),
  },
  {
    path: AUDIT_LOG,
    lazy: () => import(/* webpackChunkName: "Assets:AuditLog" */ '../scenes/AuditLog'),
    intlMessage: defineMessage({ id: 'route.assets.auditLog', defaultMessage: 'Audit Log' }),
    Icon: AuditIcon,
    restrictRoleAccess: USER_ROLES.ADMIN,
  },
];

const assets = {
  path: MODULE_PATH,
  lazy: () => import(/* webpackChunkName: "Assets" */ 'Assets'),
  intlMessage: defineMessage({ id: 'route.assets', defaultMessage: 'Assets' }),
  Icon: <MeteringPointIcon />,
  moduleNameForOrganisationAccess: MODULE_NAMES.ASSETS,
  children,
};

export const useRoutes = () => {
  const location = useLocation();
  const routes = useMemo(
    () => ({
      intlMessage: assets.intlMessage,
      root: assets.path,
      Icon: assets.Icon,
      moduleNameForOrganisationAccess: assets.moduleNameForOrganisationAccess,
      routes: assets.children.map(({ path, intlMessage, Icon, restrictRoleAccess, index }) => ({
        path,
        intlMessage,
        Icon,
        restrictRoleAccess,
        params: getParams(location.search),
        index,
      })),
    }),
    [location]
  );
  return routes;
};

export default assets;
