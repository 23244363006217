import React from 'react';
import { FormattedMessage } from 'react-intl';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';

const LOCALE_BASE = 'Datalake-assetModal-dataLog-stridePeriod-';

export const STRIDE_PERIODS = ['None', 'Daily', 'Weekly', 'Monthly'];
export const DEFAULT_STRIDE_PERIOD = STRIDE_PERIODS[0];
export const MONTHLY_MEAN_STRIDE_PERIOD = STRIDE_PERIODS[1];

const StridePeriodSelect = ({ stridePeriod, onChange, className, sx }) => (
  <FormControl className={className} sx={sx}>
    <InputLabel htmlFor="interval-select">
      <FormattedMessage id="Datalake-assetModal-dataLog-stridePeriod-label" defaultMessage="Stride Period" />
    </InputLabel>
    <Select
      value={stridePeriod}
      onChange={e => onChange(e.target.value)}
      inputProps={{
        name: 'Stride Period',
        id: 'stride-period-select',
      }}
    >
      {STRIDE_PERIODS.map(period => (
        <MenuItem key={period} value={period}>
          <FormattedMessage id={`${LOCALE_BASE}${period}`} defaultMessage={period} />
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

export default StridePeriodSelect;
