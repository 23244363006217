import React from 'react';
import { useIntl } from 'react-intl';
import utilihiveLogo from './utilihive-logo-registered-trademark.png';

const Logo = () => {
  const intl = useIntl();
  return (
    <img alt={`${intl.formatMessage({ id: 'general.utilihive' })}®`} src={utilihiveLogo} style={{ width: '100%' }} />
  );
};

export default Logo;
