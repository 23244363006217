import React from 'react';
import { FormattedMessage } from 'react-intl';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';

const LOCALE_BASE = 'Datalake-assetModal-dataLog-accumulation-';

export const ACCUMULATIONS = ['None', 'Summation'];
export const DEFAULT_ACCUMULATION = ACCUMULATIONS[0];

const AccumulationSelect = ({ accumulation, onChange, className, sx }) => (
  <FormControl className={className} sx={sx}>
    <InputLabel htmlFor="interval-select">
      <FormattedMessage id="Datalake-assetModal-dataLog-accumulation-label" defaultMessage="Accumulation" />
    </InputLabel>
    <Select
      value={accumulation}
      onChange={e => onChange(e.target.value)}
      inputProps={{
        name: 'Accumulation',
        id: 'accumulation-select',
      }}
    >
      {ACCUMULATIONS.map(accumulation => (
        <MenuItem key={accumulation} value={accumulation}>
          <FormattedMessage id={`${LOCALE_BASE}${accumulation}`} defaultMessage={accumulation} />
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

export default AccumulationSelect;
