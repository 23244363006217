import React, { Suspense } from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import ErrorBoundary from '../components/ErrorBoundary';
import NotFound from '../components/NotFound';
import Login from '../scenes/Login';
import SetPassword from '../scenes/SetPassword';
import ForgotPassword from '../scenes/ForgotPassword';
import routes from '../config/routes';
import AppWrapper from '../scenes/App/AppWrapper';
import Fallback from '../components/Fallback/Fallback';

const router = createBrowserRouter([
  {
    path: '/forgotpassword',
    element: <ForgotPassword />,
    errorElement: <ErrorBoundary appFallbackUI={true} />,
  },
  {
    path: '/login',
    element: <Login />,
    errorElement: <ErrorBoundary appFallbackUI={true} />,
  },
  {
    path: '/setpassword/:token',
    element: <SetPassword />,
    errorElement: <ErrorBoundary appFallbackUI={true} />,
  },
  {
    path: '/',
    errorElement: <ErrorBoundary appFallbackUI={true} />,
    element: <AppWrapper />,
    children: routes
      .map(({ path, element: Component, children, lazy }) => ({
        path: `${path}/*`,
        element: Component ? <Component /> : undefined,
        lazy,
        children: children
          ? children
              .map(({ path, element: Component, index, lazy }) => ({
                path,
                index,
                lazy,
                element: Component ? (
                  <Suspense fallback={<Fallback />}>
                    <Component />
                  </Suspense>
                ) : (
                  undefined
                ),
                errorElement: <ErrorBoundary appFallbackUI={false} />,
              }))
              .concat({
                path: '*',
                element: <NotFound />,
              })
          : undefined,
      }))
      .concat([
        {
          path: '*',
          element: <NotFound appFallbackUI={true} />,
        },
      ]),
  },
]);

const Router = () => <RouterProvider router={router} />;

export default Router;
