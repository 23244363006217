import { refreshToken as refreshTokenApi } from 'utilihive-authentication/client';
import { loginUrl } from '../../App/api/appUrls';
import { selectors, creators } from './tokenDuck';
import errorCodes from '../../util/errorCodes';

export const refreshTokenAction = (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const refreshToken = selectors.refreshToken(getState());
    refreshTokenApi({ refreshToken, refreshUrl: loginUrl })
      .then(token => {
        dispatch(creators.tokenRefreshSuccess(token.token, token.refreshToken, token.expires));
        resolve();
      })
      .catch(e => {
        const error = new Error(errorCodes.app.REFRESH_TOKEN_ERROR);
        dispatch(creators.tokenRefreshFailed(error));
        reject();
      });
  });
