import React from 'react';
import { FormattedMessage } from 'react-intl';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';

const LOCALE_BASE = 'Datalake-assetModal-dataLog-macroAggregate-';

export const MACRO_AGGREGATIONS = ['None', 'Average', 'Sum', 'Maximum', 'Minimum'];
export const DEFAULT_MACRO_AGGREGATE = MACRO_AGGREGATIONS[0];
export const MONTHLY_MEAN_MACRO_AGGREGATE = MACRO_AGGREGATIONS[1];

const MacroAggregateSelect = ({ macroAggregate, onChange, className, disableNone, sx }) => (
  <FormControl className={className} sx={sx}>
    <InputLabel htmlFor="macro-aggregate-select">
      <FormattedMessage id="Datalake-assetModal-dataLog-macroAggregate-label" defaultMessage="Macro aggregate" />
    </InputLabel>
    <Select
      value={macroAggregate}
      onChange={e => onChange(e.target.value)}
      inputProps={{
        name: 'Macro aggregate',
        id: 'macro-aggregate-select',
      }}
    >
      {MACRO_AGGREGATIONS.filter(aggregation => (disableNone ? aggregation !== MACRO_AGGREGATIONS[0] : true)).map(
        aggregation => (
          <MenuItem key={aggregation} value={aggregation}>
            <FormattedMessage id={`${LOCALE_BASE}${aggregation}`} defaultMessage={aggregation} />
          </MenuItem>
        )
      )}
    </Select>
  </FormControl>
);

export default MacroAggregateSelect;
