import createFetchDuck from '../../util/genericEntityDuck';

export const storeKey = 'dashboardDay';
const duck = createFetchDuck('VEE', storeKey, 'id', 'vee').extend({
  initialState: {
    data: {},
  },
});

export default duck;
export const { creators, reducer, types, selectors } = duck;
