import moment from 'moment';
import { refreshTokenAction } from './actions';
import { selectors, creators } from './tokenDuck';
import { creators as authCreators } from 'App/ducks/authDuck';

export const TIME_BEFORE_EXPIRY = 5 * 60 * 1000;

const getDurationToDelay = expires => moment(new Date(expires)).diff(moment(), 'milliseconds');

let promise = null;

const logout = dispatch => e => {
  dispatch(authCreators.logout());
  dispatch(creators.clearToken());
};

export const jwtMiddleware = ({ dispatch, getState }) => next => action => {
  if (typeof action === 'function') {
    const state = getState();
    const tokenObject = selectors.tokenObject(state);
    if (tokenObject.refreshToken && getDurationToDelay(tokenObject.expires) < TIME_BEFORE_EXPIRY && !promise) {
      promise = refreshTokenAction(dispatch, getState);
      promise
        .then(() => {
          next(action);
          promise = null;
        })
        .catch(() => {
          logout(dispatch)();
          promise = null;
        });
    } else if (promise) {
      promise
        .then(() => {
          next(action);
        })
        .catch(() => {
          logout(dispatch)();
          promise = null;
        });
    } else {
      return next(action);
    }
  } else {
    return next(action);
  }
};
