import React from 'react';
import { FormattedMessage } from 'react-intl';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';

const LOCALE_BASE = 'Datalake-assetModal-dataLog-macroPeriod-';

export const MACRO_PERIODS = ['None', 'Daily', 'Weekly', 'Monthly'];
export const DEFAULT_MACRO_PERIOD = MACRO_PERIODS[0];
export const MONTHLY_MEAN_MACRO_PERIOD = MACRO_PERIODS[3];

const MacroPeriodSelect = ({ macroPeriod, onChange, className, sx }) => (
  <FormControl className={className} sx={sx}>
    <InputLabel htmlFor="interval-select">
      <FormattedMessage id="Datalake-assetModal-dataLog-macroPeriod-label" defaultMessage="Macro Period" />
    </InputLabel>
    <Select
      value={macroPeriod}
      onChange={e => onChange(e.target.value)}
      inputProps={{
        name: 'Macro Period',
        id: 'macro-period-select',
      }}
    >
      {MACRO_PERIODS.map(period => (
        <MenuItem key={period} value={period}>
          <FormattedMessage id={`${LOCALE_BASE}${period}`} defaultMessage={period} />
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

export default MacroPeriodSelect;
