import { useMemo } from 'react';
import { defineMessage } from 'react-intl';
import VeeIcon from 'utilihive-icons/Vee';
import DashboardIcon from 'utilihive-icons/Dashboard';
import ConfigurationIcon from 'utilihive-icons/Configuration';
import UsagePointIcon from 'utilihive-icons/MeteringPoint';
import InsightsIcon from '@mui/icons-material/Insights';
import MODULE_NAMES from 'App/config/moduleNames';

export const MODULE_PATH = 'vee';
export const DASHBOARD = '';
export const PERFORMANCE = 'performance/*';
export const CONFIGURATION = 'configuration/*';
export const ASSETS = 'assets';
export const ASSET = 'assets/:assetId';

const children = [
  {
    path: DASHBOARD,
    index: true,
    lazy: () => import(/* webpackChunkName: "VEE:VEEDashboard" */ '../scenes/VEEDashboard'),
    intlMessage: defineMessage({ id: 'route.vee.dashboard', defaultMessage: 'Dashboard' }),
    Icon: DashboardIcon,
  },
  {
    path: ASSETS,
    lazy: () => import(/* webpackChunkName: "VEE:Assets" */ '../scenes/Assets'),
    intlMessage: defineMessage({ id: 'route.vee.usage.point', defaultMessage: 'MPAN' }),
    Icon: UsagePointIcon,
  },
  {
    path: ASSET,
    lazy: () => import(/* webpackChunkName: "VEE:Asset" */ '../scenes/Asset'),
  },
  {
    path: PERFORMANCE,
    lazy: () => import(/* webpackChunkName: "VEE:Performance" */ '../scenes/Performance'),
    intlMessage: defineMessage({ id: 'route.vee.performance', defaultMessage: 'Performance' }),
    Icon: InsightsIcon,
  },
  {
    path: CONFIGURATION,
    lazy: () => import(/* webpackChunkName: "VEE:Configuration" */ '../scenes/Configuration'),
    intlMessage: defineMessage({ id: 'route.vee.configuration', defaultMessage: 'Configuration' }),
    Icon: ConfigurationIcon,
  },
  {
    path: CONFIGURATION + '/vee-profile/:veeProfileId',
    lazy: () => import(/* webpackChunkName: "VEE:Configuration" */ '../scenes/Configuration/VEEProfile'),
  },
];

export const vee = {
  path: MODULE_PATH,
  lazy: () => import(/* webpackChunkName: "VEE" */ 'Vee'),
  intlMessage: defineMessage({ id: 'route.vee', defaultMessage: 'VEE' }),
  Icon: <VeeIcon />,
  moduleNameForOrganisationAccess: MODULE_NAMES.VEE,
  children,
};

export const useRoutes = () => {
  const routes = useMemo(
    () => ({
      intlMessage: vee.intlMessage,
      Icon: vee.Icon,
      root: vee.path,
      moduleNameForOrganisationAccess: vee.moduleNameForOrganisationAccess,
      routes: vee.children
        .filter(child => !!child.intlMessage)
        .map(({ path, Icon, intlMessage, index }) => ({
          path,
          intlMessage,
          Icon,
          index,
        })),
    }),
    []
  );
  return routes;
};

export default vee;
