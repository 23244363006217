import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';

const Scene = ({ className, noMargin, noBar, noMenu, ...props }) => {
  return (
    <Box
      sx={[
        noBar
          ? {
              height: '100vh',
              overflowY: 'auto',
            }
          : { height: 'calc(100vh - 64px)', overflowY: 'auto' },
      ]}
    >
      <Box
        className={className}
        sx={[
          {
            overflowX: 'hidden',
          },
          noMargin
            ? { ml: 0 }
            : {
                mt: 1,
                mx: 2,
                mb: 0,
              },
          noMenu ? { ml: 0 } : null,
        ]}
        {...props}
      />
    </Box>
  );
};

Scene.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  noMargin: PropTypes.bool,
  noBar: PropTypes.bool,
};

Scene.defaultProps = {
  className: undefined,
  noMargin: false,
  noBar: false,
};

export default Scene;
