import React, { useMemo, useEffect } from 'react';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { MODULE_PRIORITY } from '../../../domain/modules';
import GreenbirdLogo from '../../../presentation/components/Logo/Greenbird_bird_icon.svg?react';
import { Navigate, useLocation } from 'react-router-dom';
import { useOrganizationShortNameHandler } from './useOrganizationShortNameHandler';

const LoadingIcon = () => {
  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '90vh' }}
    >
      <GreenbirdLogo style={{ width: 200 }} />
      <LinearProgress color="primary" sx={{ width: 400, mt: 10 }} />
    </Box>
  );
};

const Initialize = ({
  children,
  user,
  organizationShortName,
  featuresInitialized,
  configurationInitialized,
  modules,
  token,
  fetchFeatures,
}) => {
  const location = useLocation();
  useOrganizationShortNameHandler(organizationShortName);
  useEffect(() => {
    if (!!organizationShortName && !!token) {
      fetchFeatures();
    }
  }, [fetchFeatures, organizationShortName, token]);
  const isDoneLoading = useMemo(
    () => !!user.userId && !!organizationShortName && featuresInitialized && configurationInitialized,
    [user, organizationShortName, featuresInitialized, configurationInitialized]
  );
  if (!token) {
    return (
      <Navigate to={{ pathname: '/login', state: { from: location.pathname + (location.search || '') } }} replace />
    );
  } else if (isDoneLoading && location.pathname === '/') {
    const module = MODULE_PRIORITY.find(module => modules.includes(module.key));
    if (module) {
      return <Navigate to={module.path} replace />;
    } else {
      return <Navigate to="/settings" replace />;
    }
  } else if (isDoneLoading) {
    return children;
  } else {
    return <LoadingIcon />;
  }
};

export default Initialize;
