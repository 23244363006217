import { FEATURE_TOGGLES } from 'App/ducks/featureDuck';
import { useFeatureToggle } from 'sharedComponents/FeatureToggle';
import { useEffect } from 'react';
import { registerBroadcast } from 'util/broadcast';

const useMultiScreenRegister = () => {
  const multiScreen = useFeatureToggle(FEATURE_TOGGLES.MULTI_SCREEN);
  useEffect(() => {
    if (multiScreen) {
      const channel = registerBroadcast();
      return () => channel.close();
    }
  }, [multiScreen]);
};

export default useMultiScreenRegister;
