import moment from 'moment';

export const TIME_UNITS = { MINUTE: 'MINUTE', HOUR: 'HOUR', DAY: 'DAY' };

export const dateRangeOptions = () => [
  {
    fromMoment: moment().startOf('day'),
    toMoment: moment().endOf('day'),
    label: intl => intl.formatMessage({ id: 'PresetDateRangePicker-option-today', defaultMessage: 'Today' }),
  },
  {
    fromMoment: moment()
      .startOf('day')
      .subtract(1, 'day'),
    toMoment: moment().startOf('day'),
    label: intl => intl.formatMessage({ id: 'PresetDateRangePicker-option-yesterday', defaultMessage: 'Yesterday' }),
  },
  {
    fromMoment: moment().startOf('week'),
    toMoment: moment().endOf('week'),
    label: intl => intl.formatMessage({ id: 'PresetDateRangePicker-option-week', defaultMessage: 'This week' }),
  },
  {
    fromMoment: moment()
      .startOf('week')
      .subtract(1, 'week'),
    toMoment: moment()
      .endOf('week')
      .subtract(1, 'week'),
    label: intl => intl.formatMessage({ id: 'PresetDateRangePicker-option-lastWeek', defaultMessage: 'Last week' }),
  },
  {
    fromMoment: moment().startOf('month'),
    toMoment: moment().endOf('month'),
    label: intl => intl.formatMessage({ id: 'PresetDateRangePicker-option-month', defaultMessage: 'This month' }),
  },
  {
    fromMoment: moment()
      .startOf('month')
      .subtract(1, 'month'),
    toMoment: moment()
      .endOf('month')
      .subtract(1, 'month'),
    label: intl => intl.formatMessage({ id: 'PresetDateRangePicker-option-lastMonth', defaultMessage: 'Last month' }),
  },
];
