import React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import CssBaseline from '@mui/material/CssBaseline';
import PublicPageWrapper from './PublicPageWrapper';
import Logo from '../Logo';

const paperSx = {
  backgroundColor: 'background.primary',
  marginTop: 8,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  py: 2,
  px: 3,
  ml: {
    sx: 3,
    sm: 'auto',
  },
  mr: {
    sx: 3,
    sm: 'auto',
  },
  width: {
    sm: '400px',
  },
};

const PublicPageFormPaper = ({ children }) => (
  <PublicPageWrapper>
    <CssBaseline />
    <Paper sx={paperSx}>
      <Box sx={{ width: '50%' }}>
        <Logo />
      </Box>
      {children}
    </Paper>
  </PublicPageWrapper>
);

export default PublicPageFormPaper;
