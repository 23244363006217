import React, { useState, useCallback, useEffect, useMemo } from 'react';
import KonamiLib from 'konami';
import Thorsten from './Thorsten';

const Konami = ({ user }) => {
  const [konami, setKonami] = useState(false);
  const konamiCallback = useCallback(() => {
    setKonami(true);
  }, [setKonami]);
  const allowEasterEgg = useMemo(() => (user.email && user.email.endsWith('@greenbird.com')) || false, [user]);
  useEffect(() => {
    const easterEgg = new KonamiLib(konamiCallback);
    return () => {
      easterEgg.unload();
    };
  }, [konamiCallback]);
  return allowEasterEgg && konami ? <Thorsten /> : null;
};

export default Konami;
