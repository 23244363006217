import { combineReducers } from 'redux';

import { reducer as assetsReducer, storeKey as assetsKey } from './assets';
import { reducer as assetReducer, storeKey as assetKey } from './asset';
import { reducer as approvalsReducer, storeKey as approvalsKey } from './approvals';
import { reducer as dashboardReducer, storeKey as dashboardKey } from './dashboard';
import { reducer as searchReducer, storeKey as searchKey } from './searchDuck';

const market = combineReducers({
  [assetsKey]: assetsReducer,
  [approvalsKey]: approvalsReducer,
  [assetKey]: assetReducer,
  [dashboardKey]: dashboardReducer,
  [searchKey]: searchReducer,
});

export default market;
