import { combineReducers } from 'redux';
import { reducer as assetDataLogForm, storeKey as assetDataLogFormKey } from './assetDataLogFormDuck';
import { reducer as usagePoints, storeKey as usagePointsKey } from './usagePoints';
import { reducer as configuration, storeKey as configurationKey } from './configuration';
import { reducer as endDevices, storeKey as endDevicesKey } from './endDevices';
import { reducer as masterDataLinkages, storeKey as masterDataLinkagesKey } from './masterDataLinkages';
import { reducer as events, storeKey as eventsKey } from './events';
import { reducer as readings, storeKey as readingsKey } from './readings';
import { reducer as workflows, storeKey as workflowsKey } from './workflows';
import { reducer as workflowsArchive, storeKey as workflowsArchiveKey } from './workflowsArchive';

const datalake = combineReducers({
  [assetDataLogFormKey]: assetDataLogForm,
  [usagePointsKey]: usagePoints,
  [configurationKey]: configuration,
  [endDevicesKey]: endDevices,
  [masterDataLinkagesKey]: masterDataLinkages,
  [eventsKey]: events,
  [readingsKey]: readings,
  [workflowsKey]: workflows,
  [workflowsArchiveKey]: workflowsArchive,
});

export default datalake;
