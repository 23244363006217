import { login } from '../../config/backendRoutes';

export const loginUrl = `${login}/authentication/token`;
export const activeDirectoryEndpoint = `${login}/authentication/activedirectory`;
export const userDetailsEndpoint = `${login}/users`;
export const organizationDetailsEndpointUrl = `${login}/organizations`;

const passwordBase = `${login}/password`;
export const resetPasswordEndpoint = `${passwordBase}/set`;
export const sendResetPasswordMailEndpoint = `${passwordBase}/reset`;
export const isResetPasswordTokenValidEndpoint = token => `${sendResetPasswordMailEndpoint}/${token}`;
