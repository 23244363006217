export const MODULES = ['HEARTBEAT', 'DATA_LAKE', 'SMOC', 'ASSETS', 'GHOSTWRITER', 'M3', 'EVE', 'VEE', 'PLATFORM'];

export const MODULE_PRIORITY = [
  {
    key: 'HEARTBEAT',
    path: '/heartbeat',
  },
  {
    key: 'DATA_LAKE',
    path: '/datalake',
  },
  {
    key: 'ASSETS',
    path: '/assets',
  },
  {
    key: 'SMOC',
    path: '/smoc',
  },
  {
    key: 'M3',
    path: '/market',
  },
  {
    key: 'VEE',
    path: '/vee',
  },
  {
    key: 'GHOSTWRITER',
    path: '/ghostwriter',
  },
  {
    key: 'PLATFORM',
    path: '/platform',
  },
];
