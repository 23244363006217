import { creators, MULTISCREEN_KEY } from 'App/ducks/multiScreenDuck';
import { useDispatch } from 'react-redux';
import { FEATURE_TOGGLES } from 'App/ducks/featureDuck';
import { useFeatureToggle } from 'sharedComponents/FeatureToggle';
import { useEffect, useState } from 'react';
import { getMonitors } from 'util/broadcast';

const useMultiScreenSettings = () => {
  const [monitors, setMonitors] = useState(1);
  const dispatch = useDispatch();
  const multiScreenFeature = useFeatureToggle(FEATURE_TOGGLES.MULTI_SCREEN);
  useEffect(() => {
    dispatch(creators.setMonitors(monitors));
  }, [monitors, dispatch]);
  useEffect(() => {
    if (multiScreenFeature) {
      const get = async () => {
        const monitors = await getMonitors();
        setMonitors(monitors);
      };
      get();
      const interval = setInterval(get, 10000);
      return () => clearInterval(interval);
    }
  }, [setMonitors, multiScreenFeature]);
  useEffect(() => {
    const onEvent = e => {
      if (e.key === MULTISCREEN_KEY) {
        dispatch(creators.setIsMultiScreen(JSON.parse(e.newValue)));
      }
    };
    window.addEventListener('storage', onEvent);
    return () => window.removeEventListener('storage', onEvent);
  }, [dispatch]);
};

export default useMultiScreenSettings;
