import { combineReducers } from 'redux';

import { reducer as locationDtoReducer, storeKey as locationDtoKey } from './locationDto';
import {
  reducer as identifiedObjectTypePropertyReducer,
  storeKey as identifiedObjectTypePropertyKey,
} from './identifiedObjectTypeProperty';
import { reducer as usagePointLocationDtoReducer, storeKey as usagePointLocationDtoKey } from './usagePointLocationDto';
import { reducer as usagePointReducer, storeKey as usagePointKey } from './usagePoint';
import { reducer as readingsReducer, storeKey as readingsKey } from './readings';
import { reducer as eventsReducer, storeKey as eventsKey } from './events';
import { reducer as configurationReducer, storeKey as configurationKey } from './configuration';
import { reducer as issueDtoReducer, storeKey as issueDtoKey } from './issueDto';
import { reducer as issueCategoryReducer, storeKey as issueCategoryKey } from './issueCategory';
import { reducer as tasksReducer, storeKey as tasksKey } from './tasksDuck';
import { reducer as errors, storeKey as errorsKey } from './errorsDuck';

const smoc = combineReducers({
  [errorsKey]: errors,
  [locationDtoKey]: locationDtoReducer,
  [usagePointLocationDtoKey]: usagePointLocationDtoReducer,
  [usagePointKey]: usagePointReducer,
  [identifiedObjectTypePropertyKey]: identifiedObjectTypePropertyReducer,
  [readingsKey]: readingsReducer,
  [eventsKey]: eventsReducer,
  [configurationKey]: configurationReducer,
  [issueDtoKey]: issueDtoReducer,
  [issueCategoryKey]: issueCategoryReducer,
  [tasksKey]: tasksReducer,
});

export default smoc;
