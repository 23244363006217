import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Slide from '@mui/material/Slide';

const Thorsten = () => {
  const [image, setImage] = useState(null);
  const [showHim, setShowHim] = useState(false);
  useEffect(() => {
    fetch('/whack.png')
      .then(response => response.blob())
      .then(blob => {
        setImage(URL.createObjectURL(blob));
        setShowHim(true);
        setTimeout(() => setShowHim(false), 2000);
      })
      .catch(() => {});
  }, []);
  return (
    <Slide direction="up" in={showHim} mountOnEnter unmountOnExit>
      <Box sx={{ position: 'fixed', right: 100, bottom: 0, zIndex: 3000, height: 500 }}>
        <img alt="Easter egg" height={500} src={image} />
      </Box>
    </Slide>
  );
};

export default Thorsten;
