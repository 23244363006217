import { grey } from '@mui/material/colors';

const surface = {
  '1': '#000000',
  '2': '#1b2a33',
  '3': '#121f28',
  '4': '#162530',
  '5': '#23343f',
  '6': '#0c1419',
  '7': '#05090c',
  '8': '#2c404d',
};

const borders = {
  white: '#ffffff',
  minimal: '#0c1419',
  subtle: '#121f26',
  moderate: '#324e60',
  bold: '#23343f',
  strong: '#4c6472',
  focus: '#77cdfc',
  black: '#121f26',
};

const feedback = {
  information: '#007aac',
  danger: '#b40000',
  danger2: '#ed5120',
  serious: '#ff8b3a',
  warning: '#fec602',
  warning2: '#ffe68b',
  success: '#7faf1c',
  neutral: '#d8e0e5',
  neutralAlt: '#e5f5ff',
};

const text = {
  primary: '#ffffff',
  seconday: '#b6c3cc',
  reverse: '#121f26',
};

const navigation = {
  openItemBackground: surface['2'],
  background: surface['8'],
  active: '#084c73',
};

const palette = {
  mode: 'dark',
  primary: { main: '#007aac' },
  secondary: { main: '#217554' },
  success: { main: feedback.success },
  warning: { main: feedback.warning },
  error: { main: '#f34336', dark: feedback.danger },
  borders,
  surface,
  info: {
    main: feedback.information,
  },
  background: {
    paper: surface['3'],
    default: surface['7'],
    contrast: grey[900],
    primary: surface['5'],
  },
  primaryGrape: {
    main: '#773999',
    dark: '#341b43',
  },
  text,
  navigation,
  alternateTable: {
    main: '#3a3a3a',
  },
  graph: {
    'graph-1': '#88c666',
    'graph-2': '#f05d2a',
    'graph-3': '#ffde00',
    main: [
      '#7BDCB5',
      '#00D084',
      '#8ED1FC',
      '#0693E3',
      '#FF6900',
      '#FCB900',
      '#ABB8C3',
      '#EB144C',
      '#F78DA7',
      '#9900EF',
    ],
    dark: [
      '#578572',
      '#00915c',
      '#6392b0',
      '#04669e',
      '#b24900',
      '#b08100',
      '#778088',
      '#a40e35',
      '#ac6274',
      '#6b00a7',
    ],
  },
};

export default palette;
